import _ from 'lodash';

(() => {
  angular
    .module('finance')
    .controller('finance.views.myInvoisSubmission', MyInvoisSubmissionController);

  MyInvoisSubmissionController.$inject = [
    '$stateParams',
    '$timeout',
    'financeConstants',
    'abp.services.finance.commonLookup'
  ];

  function MyInvoisSubmissionController(
    $stateParams,
    $timeout,
    financeConsts,
    commonLookupSvc,
  ) {
    const vm = this;
    vm.tabIndex = Number(0);
    vm.requestParams = new RequestParamsModel($stateParams);

    vm.permissions = {
      test: abp.auth.isGranted('HealthMetrics.Finance.MyInvoisSubmission.Test'),
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: financeConsts.grid.defaultPageSizes,
      paginationPageSize: financeConsts.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: getGridOptionsColumnDefs(0),
      data: [
        {
          transactionNumber: 'IN-2204-012345',
          submissionId: '1234',
          documentId: '1234',
          status: 1,
          amount: 100
        }
      ],
    };

    vm.getGridOptionsColumnDefs = getGridOptionsColumnDefs;
    vm.setActiveView = setActiveView;
    vm.getSettings = getSettings;

    init();

    // Init function.

    function init() {
      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });

      getSettings();
    }

    function getSettings() {
      vm.loading += 1;
      commonLookupSvc
        .getCommonSettings()
        .success((data) => {
          vm.hasMyInvoisSubmission = data.myInvoisSubmission;
        })
        .finally(() => {
      vm.loading -= 1;
        });
    }

    function getGridOptionsColumnDefs(isPayment) {
      const columnDefs = [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('TransactionNumber'),
          enableSorting: false,
          field: 'transactionNumber',
          minWidth: 100
        },
        {
            displayName: App.localize('SubmissionId'),
            enableSorting: false,
            field: 'submissionId',
            minWidth: 100
        },
        {
            displayName: App.localize('DocumentId'),
            enableSorting: false,
            field: 'documentId',
            minWidth: 100
        },
        {
            displayName: App.localize('Status'),
            enableSorting: false,
            field: 'status',
            minWidth: 100,
            cellTemplate: 'statusTemplate'
        },
        {
          displayName: App.localize('Amount'),
          enableSorting: false,
          field: 'amount',
          minWidth: 100
        }
      ];

      return columnDefs;
    }

    function setActiveView(viewId) {
      vm.tabIndex = Number(viewId);
      vm.gridOptions.paginationPageSize = financeConsts.grid.defaultPageSize;
      vm.gridOptions.paginationCurrentPage = 1;
      vm.gridOptions.columnDefs = getGridOptionsColumnDefs(viewId);
    }

    function RequestParamsModel(params) {
      this.skipCount = params.skipCount || 0;
      this.maxResultCount = params.maxResultCount || financeConsts.grid.defaultPageSize;
      this.sorting = params.sorting;

      this.toStateParams = () => {
        const stateParams = {
          skipCount: this.skipCount,
          maxResultCount: this.maxResultCount,
          sorting: this.sorting
        };
        return stateParams;
      };
    }
  }
})();
